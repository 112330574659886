import axios from 'axios';
import { DOMAIN } from '../config/env';

/**
 * Recuperes tous les bordereaux pour le dashboard des litiges
 */
export async function getAllBordereaux() {
  let bordereaux = {};
  await axios
    .get(DOMAIN.URL_API + 'litEnvoi', {
      withCredentials: true,
    })
    .then((res) => {
      bordereaux = res.data;
    });
  return bordereaux;
}

/**
 * Envoi un mail de notification pour signaler de nouveaux litiges
 */
export async function sendNotificationLitige(con_code) {
  const data = {
    con_code: con_code,
  };
  await axios.post(
    DOMAIN.URL_API + 'litige/notification-rfa',
    { ...data },
    { withCredentials: true }
  );
}

/**
 * Ouvre ou valide un bordereau
 */
export async function toggleValidationLitige(lev_numero) {
  const data = {
    lev_numero: lev_numero,
  };
  await axios.post(
    DOMAIN.URL_API + 'litige/toggle-validation-rfa',
    { ...data },
    { withCredentials: true }
  );
}
