import React, { useState } from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
// import logoIcon from './logo_nav_bar.png';
import logoIcon from './logo-referencia.png';
import Hamburger from './Hamburger';
import useAuth from '../../../../hooks/useAuth';
import NavLink from './NavLink';
import { PATH } from '../../../../config/constants';

const Nav = () => {
  const { user, isAdmin, isReferencia, onLogout } = useAuth();
  const [selectedLinkIndex, setSelectedLinkIndex] = useState(-1);

  const handleLogout = () => {
    setSelectedLinkIndex(-1);
    onLogout();
  };

  const links = [
    {
      label: 'Accueil',
      to: PATH.HOME,
    },
    {
      label: 'Déclaration',
      to: PATH.DECLARATION_DETAIL,
      forward: true,
    },
    {
      label: 'Litiges',
      to: PATH.LITIGE_DETAIL,
      forward: true,
    },
    {
      label: 'Tableau de bord',
      to: PATH.DASHBOARD,
      visible: isAdmin() || isReferencia(),
    },
    {
      label: 'Aide',
      to: PATH.AIDE,
    },
  ];

  const manageLinks = [
    {
      label: user.name,
      items: [
        { label: 'Profile', to: '/profile' },
        { label: 'Deconnexion', onClick: handleLogout },
      ],
    },
  ];

  const handleClick = (event) => {
    const { target } = event;
    if (!target.closest('.dropdown')) {
      setSelectedLinkIndex(-1);
    }
  };

  React.useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, []);

  return (
    <>
      <nav className="nav">
        <div className="nav__links">
          <Link to="/">
            <img className="nav__logo" src={logoIcon} alt="logo" />
          </Link>
          <ul className="dropdown">
            {links.map((link, index) => (
              <NavLink
                key={link.label}
                link={link}
                selected={selectedLinkIndex === index}
                visible={link.visible}
                onClick={() => setSelectedLinkIndex(index)}
                forward={link.forward}
              />
            ))}
          </ul>
        </div>
        <div className="nav__actions">
          <ul className="dropdown">
            {manageLinks.map((link, index) => (
              <NavLink
                key={link.label}
                link={link}
                selected={selectedLinkIndex === index}
                onClick={() => setSelectedLinkIndex(index)}
              />
            ))}
          </ul>
        </div>
        <div className="nav__burger">
          <Hamburger />
        </div>
      </nav>
    </>
  );
};

export default Nav;
