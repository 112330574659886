import React, { useState } from 'react';
import './style.scss';
import EyeIcon from '../../icons/EyeIcon';
import EyeSlashIcon from '../../icons/EyeSlashIcon';

const PasswordInput = ({
  label,
  title,
  onChange,
  error,
  ...rest
}) => {
  const [seePassword, setSeePassword] = useState(false);
  const togglePassword = () => {
    setSeePassword(!seePassword);
  };

  return (
    <div className="password-field">
      <div className="field">
        <label title={title}>{label}</label>
        <input
          {...rest}
          type={seePassword ? 'text' : 'password'}
          onChange={onChange}
        />
        <button
          type="button"
          className="icon"
          onClick={togglePassword}
          tabIndex="-1"
        >
          {seePassword ? (
            <EyeSlashIcon width="15" height="15" />
          ) : (
            <EyeIcon width="15" height="15" />
          )}
        </button>
      </div>

      <span className="error">{error ? <>{error}</> : null}</span>
    </div>
  );
};

export default PasswordInput;
