import React from 'react';
import ReactDOM from 'react-dom';
import './style.scss';

/**
 * Notification basique
 * @param isShowing indicateur d'affichage de la notification
 * @param hide fonction permettant de cacher/supprimer la notification
 * @param text le texte à afficher sur la notification
 */
const BasicNotification = ({ isShowing, hide, text }) => {
  const time = 4000; // Durée d'affichage de la notification

  // Retire la notification au bout d'un certain laps de temps
  React.useEffect(() => {
    if (isShowing) {
      new Promise((r) => setTimeout(r, time)).then(() => {
        hide();
      });
    }
  }, [hide, isShowing]);

  if (isShowing) {
    return ReactDOM.createPortal(
      <>
        <div className="notification-overlay">
          <div className="notification">
            <p>{text}</p> <span className="progress"></span>
          </div>
        </div>
      </>,
      document.body
    );
  } else {
    return null;
  }
};

export default BasicNotification;
