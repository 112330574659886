import React, { useState } from "react";
import { Modal, Box } from "@mui/material";
import UserContratModal from "./UserContratModal";
import Button from "../../../../button/Button";
import EditIcon from "../../../../icons/EditIcon";

// Component that triggers the modal and contains the UserContratModal
const UserContratModalTrigger = ({ userId }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  // Function to handle modal open
  const handleOpen = () => setModalOpen(true);

  // Function to handle modal close
  const handleClose = () => setModalOpen(false);

  // Styles for the modal
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Button
        libelle=" "
        onClick={handleOpen}
        color="color-neutral"
        icon={<EditIcon with={18} height={18} />}
      />

      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="manage-contracts-modal"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <UserContratModal userId={userId} />
        </Box>
      </Modal>
    </>
  );
};

export default UserContratModalTrigger;
