import React from 'react';
import styled from 'styled-components';

const Path = styled.path`
  fill: currentColor;
`;

const Svg = styled.svg`
  transform: rotate(180deg);
`;

const ArrowSlideToLeft = ({ width = 20, height = 20 }) => {
  return (
    <Svg
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 20 20"
      x="0px"
      y="0px"
    >
      <g>
        <Path d="M4 16V4H2v12h2zM13 15l-1.5-1.5L14 11H6V9h8l-2.5-2.5L13 5l5 5-5 5z"></Path>
      </g>
    </Svg>
  );
};

export default ArrowSlideToLeft;
