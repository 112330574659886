import React from 'react';
import './style.scss';
import logoIcon from './logo-referencia.png';

const AuthLayout = ({ children }) => {
  return (
    <>
      <div className="auth-layout-overlay">
        <div className="auth-layout">
          <div className="auth-layout__head">
            <img className="logo" src={logoIcon} alt="logo-nav" />
            <h3 className="logo__text">Portail Inédis Référencia</h3>
          </div>
          <div className="auth-layout__body">{children}</div>
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
