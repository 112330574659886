import React from 'react';
import './style.scss';
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from 'react-table';
import { useSticky } from 'react-table-sticky';
import Pagination from './Pagination';
import Cell from './Cell';
import Filters from './Filters';
import Search from './Search';

const Table = ({
  data,
  columns,
  pagination,
  filter,
  search,
  footer,
  updateMyData,
  hiddenColumns,
  getRowProps,
}) => {
  // Noms des classes
  const stickyCellClass = 'action-cell';
  const leftBorderClass = 'border-left-cell';
  const rightBorderClass = 'border-right-cell';
  const borderedHeaderClass = 'separated';

  const props = useTable(
    {
      columns: columns,
      data,
      defaultColumn: { Cell: Cell },
      //   autoResetPage: !skipPageReset,
      updateMyData,
      initialState: {
        hiddenColumns: hiddenColumns ?? [],
        // pageSize: 20,
      },
      autoResetPage: false,
      autoResetSortBy: false,
    },
    useGlobalFilter, // useGlobalFilter!
    useSticky,
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    allColumns,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)

    state: { globalFilter },
  } = props;

  /**
   * Defini le style de la cellule
   */
  const getCellStyle = (column) => ({
    minWidth: column.width,
    maxWidth: column.width,
    textAlign: column.align,
  });

  const getHeaderStyle = (column) => ({
    minWidth: column.width,
    cursor: 'pointer',
  });

  /**
   * Defini les classes du header pour son affichage
   */
  const getHeaderClass = (headerGroup, i) => {
    const headerClass = []; // Classes du header
    // Ajoute une classe pour l'affichage de la séparation entre les headers sauf pour la derniere colonne
    headerClass.push(
      i !== headerGroup.headers.length - 1 && borderedHeaderClass
    );

    return headerClass.join(' ');
  };

  /**
   * Defini les classes de la cellule pour son affichage
   */
  const getCellClass = (cell) => {
    const cellClass = []; // Classes de la cell

    // Ajoute un style particulier aux cellules des colonnes sticky
    const cellStickyPosition = cell.column.sticky;
    cellClass.push(cellStickyPosition && stickyCellClass);
    // Ajoute une bordure à droite ou a gauche suivant la position de la cellule sticky
    switch (cellStickyPosition) {
      case 'left':
        cellClass.push(rightBorderClass);
        break;
      case 'right':
        cellClass.push(leftBorderClass);
        break;
      default:
    }

    return cellClass.join(' ');
  };

  if (!columns.length || !data.length) return null;
  return (
    <div className="table-overlay">
      <div className="table-overlay__head">
        {search && (
          <Search
            value={globalFilter}
            setValue={setGlobalFilter}
            placeholder={search.placeholder}
          />
        )}
        {filter && <Filters allColumns={allColumns} />}
      </div>
      <div className="table-overlay__body">
        <div className="table-wrapper">
          <table className="table" {...getTableProps()}>
            <thead className="table__head">
              {headerGroups.map((headerGroup) => (
                <tr
                  className="head-row"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column, i) => (
                    <th
                      className={`head-cell ${getHeaderClass(
                        headerGroup,
                        i
                      )}`}
                      {...column.getHeaderProps({
                        ...column.getSortByToggleProps(),
                        title: '',
                        style: getHeaderStyle(column),
                      })}
                    >
                      <span>{column.render('Header')}</span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="table__body" {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                const rowProps = getRowProps
                  ? {
                      ...row.getRowProps(),
                      ...getRowProps(row),
                    }
                  : row.getRowProps();
                return (
                  <tr
                    {...rowProps}
                    className={`body-row ${rowProps.className}`}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          title={cell.value}
                          className={`body-cell ${getCellClass(
                            cell
                          )}`}
                          {...cell.getCellProps({
                            style: getCellStyle(cell.column),
                          })}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {pagination && <Pagination props={props} footer={footer} />}
      </div>
    </div>
  );
};

export default Table;
