import React from 'react';
import useModal from '../../../../../hooks/useModal';
import useNotification from '../../../../../hooks/useNotification';
import { toggleClotureDeclarationRFA } from '../../../../../services/contratsRFA_apis';
import ValidationModal from '../../ValidationModal';
import WarningIcon from '../../../icons/WarningIcon';
import BasicNotification from '../../../notifications/BasicNotification';
import Button from '../../../button/Button';

const ADMtoggleClotureModal = ({ refreshData, contratRFA }) => {
  const modalState = useModal();
  const { isShowingNot, toggleNotification } = useNotification();

  /**
   * Fonction clôturant ou décloturant une déclaration
   * @param contratRFA la déclaration concernée
   */
  const handleToggleCloture = async (contratRFA) => {
    try {
      await toggleClotureDeclarationRFA(
        contratRFA.con_code,
        contratRFA.annee
      );
      refreshData();
      toggleNotification();
      modalState.toggleModal();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Button
        libelle={
          !!contratRFA.statut_declaration ? 'Réouvrir' : 'Clôturer'
        }
        color={
          !!contratRFA.statut_declaration
            ? 'color-tertiaire-variant'
            : 'color-danger'
        }
        onClick={() => modalState.toggleModal()}
      />
      <ValidationModal
        modalState={modalState}
        title={
          !!contratRFA.statut_declaration ? 'réouvrir' : 'clôturer'
        }
        color="primary-modal-bis"
        onConfirmation={() => handleToggleCloture(contratRFA)}
      >
        <>
          {!!contratRFA.statut_declaration
            ? 'réouverture'
            : 'clôture'}
          &nbsp;
          {contratRFA.con_nom}
        </>
      </ValidationModal>
      <BasicNotification
        isShowing={isShowingNot}
        hide={toggleNotification}
        text="Modification"
      />
    </>
  );
};

export default ADMtoggleClotureModal;
