import React from 'react';
import './style.scss';

const RFAtextArea = ({ label, title, onChange, error, ...rest }) => {
  return (
    <div className="rfa-textarea">
      <label title={title} className="label">
        {label}
      </label>
      <textarea
        {...rest}
        onChange={onChange}
      />
      <span className="error">{error ? <>{error}</> : null}</span>
    </div>
  );
};

export default RFAtextArea;
