import React, { useState } from 'react';
import './style.scss';
import Table from '../../../Table';
import LITvalidationModal from '../../../../modals/_RFA/_Litige/LITvalidationModal';
import LITlitigeDrawer from '../../../../drawers/_RFA/_Litige/LITlitigeDrawer';

/**
 * Table de données des litiges d'un bordereau
 */
const LITbordereauTable = ({ bordereau, regles, refetchData }) => {
  const [data, setData] = useState({}); // Litiges
  const [canValidate, setCanValidate] = useState(false); // Indicateur pour savoir si l'utilisateur peut valider ou non le litige
  const [isValidate, setIsValidate] = useState(false); // Indicateur si le bordereau a deja été cloturé ou non

  // Colonnes a ne pas afficher
  const hiddenColumns = [
    'rfa_mag.ens_code',
    'rfa_mag.raisonsociale',
    'rfa_mag.codepostal',
    'rfa_mag.mag_siret',
    'rfa_mag.mag_depots',
  ];
  // Colonnes de la table
  const columns = [
    {
      Header: 'Nom',
      accessor: 'rfa_mag.mag_nom',
      width: 160,
    },
    {
      Header: 'Enseigne',
      accessor: 'rfa_mag.enseigne.ens_nom',
      filter: 'includes',
      width: 160,
      hideable: true,
    },

    {
      Header: 'Ville',
      accessor: 'rfa_mag.adr_ville',
      width: 160,
      hideable: true,
    },
    {
      Header: 'Raison Sociale',
      accessor: 'rfa_mag.raisonsociale',
      filter: 'includes',
      width: 180,
      hideable: true,
    },
    {
      Header: 'Code postal',
      accessor: 'rfa_mag.codepostal',
      filter: 'includes',
      hideable: true,
      textAlign: 'right',
    },
    {
      Header: 'Dépot(s)',
      accessor: 'rfa_mag.mag_depots',
      width: 200,
      hideable: true,
    },
    {
      Header: 'Siret',
      accessor: 'rfa_mag.mag_siret',
      filter: 'includes',
      hideable: true,
      textAlign: 'right',
    },
    {
      Header: 'Votre déclaration initiale',
      accessor: 'lt_ar_depart',
      width: 160,
      currency: true,
      textAlign: 'right',
    },
    {
      Header: 'Déclaration du magasin',
      accessor: 'lt_ar_mag',
      width: 160,
      currency: true,
      textAlign: 'right',
    },
    {
      Header: 'Votre réponse finale',
      accessor: 'lt_ar_fourn',
      width: 100,
      currency: true,
      textAlign: 'right',
    },
    {
      Header: '',
      sticky: 'right',
      accessor: 'mag_code',
      width: 0,
      Cell: (props) => (
        <div className="center-cell" title="">
          <LITlitigeDrawer
            litige={props.row.original}
            regles={regles}
            refetchData={refetchData}
            bordereau={bordereau}
          />
        </div>
      ),
    },
  ];

  /**
   * Modifye la couleur de la ligne si celle ci est validée
   */
  const getRowProps = (row) => ({
    className:
      row.values.lt_ar_fourn || row.values.lt_ar_fourn === 0
        ? 'positive-row'
        : '',
  });

  React.useEffect(() => {
    // Met a jours les données
    setData(bordereau.litiges);
    // Verifie si tous les litiges on étés répondus
    let canValidate = true;
    bordereau.litiges.map((item) => {
      if (!item.lt_ar_fourn && item.lt_ar_fourn !== 0) {
        canValidate = false;
      }
      return true;
    });
    setCanValidate(canValidate);
    // Verifie si les litiges peuvent être modifiés
    setIsValidate(bordereau.lev_position === 1);
  }, [bordereau]);

  if (!data.length) return null;
  return (
    <div className="litiges-table-overlay">
      <div className="litiges-table">
        <Table
          data={data}
          columns={columns}
          filter
          hiddenColumns={hiddenColumns}
          getRowProps={getRowProps}
          pagination
        />
        <div className="litiges-table__actions">
          {isValidate && (
            <LITvalidationModal
              canValidate={canValidate}
              lev_numero={bordereau.lev_numero}
              refetchData={refetchData}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default LITbordereauTable;
