import React from 'react';
import Modal from '../../../Modal';
import DECtotalTable from '../../../../tables/_RFA/_Declaration/DECtotalTable';
import useModal from '../../../../../../hooks/useModal';
import Button from '../../../../button/Button';
import InfoIcon from '../../../../icons/InfoIcon';

const DECtotalModal = ({ rfaContrat, refreshContratRFA }) => {
  const modalState = useModal();

  return (
    <>
      <Button
        libelle="Total déclaré"
        onClick={() => {
          refreshContratRFA();
          modalState.toggleModal();
        }}
        color="color-tertiaire-variant"
        icon={<InfoIcon width={15} height={15} />}
      />
      <Modal
        modalState={modalState}
        title="Total déclaré"
        color="primary-modal"
      >
        <DECtotalTable rfaContrat={rfaContrat} />
      </Modal>
    </>
  );
};

export default DECtotalModal;
