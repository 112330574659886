import React, { useState } from 'react';
import Button from '../../button/Button';
import MailBgIcon from '../../icons/MailBgIcon';
import CheckBgIcon from '../../icons/CheckBgIcon';
import TrashBgIcon from '../../icons/TrashBgIcon';
import Modal from '../Modal';
import './style.scss';

const icons = {
  mail: <MailBgIcon />,
  check: <CheckBgIcon />,
  trash: <TrashBgIcon />,
};

const defaultIcon = 'check';

const ValidationModal = ({
  children,
  onConfirmation,
  icon,
  ...props
}) => {
  const [loading, setLoading] = useState(false); // Chargement pendant l'éxecution de l'evenement on click
  const [error, setError] = useState('');

  // Execute l'évenement on click
  const handleClick = () => {
    setLoading(true);
    setError('');
    onConfirmation()
      .then(() => props.modalState.toggleModal())
      .catch((error) => {
        console.log(error);
        setError('Une erreur est survenue');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Vérification de l'appartenance du libelle au tableau
  if (!icons[icon]) icon = defaultIcon;
  return (
    <Modal {...props}>
      <div className="validation-modal-wrap">
        <header className="validation-modal-bg">
          <div className="validation-mail-modal-icon">
            {icons[icon]}
          </div>
        </header>
        <div className="validation-modal">
          <h3 className="validation-modal__title">{props.title}</h3>
          <div className="validation-modal__content">{children}</div>

          <div className="validation-modal__action">
            <p>{error}</p>
            <div>
              <Button
                libelle="Confirmer"
                onClick={() => handleClick()}
                loading={loading}
              />
              <Button
                libelle="Annuler"
                color="color-neutral"
                onClick={props.modalState.toggleModal}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ValidationModal;
