import React from 'react';
import { formatPrix } from '../../../../../utils/format';
import styled from 'styled-components';

const Input = styled.input`
  position: relative;
  padding: 0.5em 1em;
  height: 100%;
  width: 100%;
  text-align: right;
  color: red;
`;

const EditableCell = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;

const StyledCell = styled.div`
  text-align: ${(props) => {
    switch (props.textAlign) {
      case 'center':
        return 'center';
      case 'right':
        return 'right';
      default:
        return 'left';
    }
  }};
`;

/**
 * Cellule de la table
 */
const Cell = ({
  value: initialValue,
  row: { index },
  column,
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally

  const [value, setValue] = React.useState(initialValue);
  const [isEditing, setIsEditing] = React.useState(false);
  const formattedValue =
    column.currency && !isEditing ? formatPrix(value) : value ?? '';
  // const editingClass = 'editing';

  const onFocus = () => {
    setIsEditing(true);
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    setIsEditing(false);
    updateMyData(index, column.id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (!column.editable)
    return (
      <StyledCell textAlign={column.textAlign}>
        {formattedValue}
      </StyledCell>
    );
  return (
    <EditableCell>
      <Input
        value={formattedValue}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </EditableCell>
  );
};

export default Cell;
