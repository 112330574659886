import React, { useState } from 'react';
import './style.scss';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import Button from '../../../components/ui/button/Button';
import { forgotPassword } from '../../../services/authentificationApis';
import BasicInput from '../../../components/ui/inputs/BasicInput';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [errors, setErrors] = useState({}); // Erreurs pour le formulaire
  const [loadingMail, setLoadingMail] = useState(false); // Indicateur de chargement pour l'envoie de mail
  const [errorMail, setErrorMail] = useState('');

  // Validation des données du formulaire
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('* Ce champs est obligatoire'),
  });

  /**
   * Fonction envoyant un lien de réinitialisation de mot de passe
   */
  const sendResetMail = () => {
    // Validation des champs de saisie
    const validate = async () => {
      const errorBag = {};
      await validationSchema
        .validate({ email: email }, { abortEarly: false })
        .catch((err) => {
          err?.inner.forEach((res) => {
            errorBag[res.path] = res.message;
          });
        });
      setErrors(errorBag);
      if (Object.keys(errorBag).length) {
        throw new Error('Champs invalides');
      }
    };
    try {
      validate().then(() => {
        setEmailSent(false);
        setLoadingMail(true);
        setErrorMail('');
        forgotPassword(email)
          .then(() => {
            setEmailSent(true);
            setLoadingMail(false);
          })
          .catch((err) => {
            console.log(err);
            setEmailSent(false);
            setLoadingMail(false);
            setErrorMail('Une erreur est survenue, réessayer.');
          });
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  return (
    <>
      <div className="form-reset">
        <h3>
          Réinitialiser <br /> le mot de passe
        </h3>
        {!emailSent ? (
          <>
            <div className="form-reset__inputs">
              <BasicInput
                type="email"
                name="email"
                label="Email"
                value={email}
                onChange={(event) => handleChange(event)}
                error={errors.email}
                autoFocus
                autoComplete="email"
              />
            </div>
            <span className="error-mail">
              {errorMail && <>{errorMail}</>}
            </span>
            <div className="form-reset__action">
              <Button
                loading={loadingMail}
                libelle="Envoyer mail"
                color="primary-btn"
                onClick={(event) => sendResetMail(event)}
              />
              <Link to="/login"> &#8592; connection </Link>
            </div>
          </>
        ) : (
          <>
            <p className="mail-sent-message">
              Un mail de réinitialisation de mot de passe vous a été
              envoyé.
            </p>
            <div className="form-reset__action">
              <Button
                libelle="Renvoyer mail"
                color="primary-btn"
                onClick={(event) => sendResetMail(event)}
              />
              <Link to="/login"> &#8592; connection </Link>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ForgotPassword;
