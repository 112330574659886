import React, { useState } from 'react';
import MissingDataCard from '../../../components/ui/cards/MissingDataCard';
import ModifyIcon from '../../../components/ui/icons/ModifyIcon';
import LoaderApp from '../../../components/ui/loaders/LoaderApp';
import LITbordereauTable from '../../../components/ui/tables/_RFA/_Litige/LITbordereauTable';
import useContrat from '../../../hooks/useContrat';
import { getBordereaux } from '../../../services/litiges_api';
import './style.scss';

const Litige = () => {
  const { contrat } = useContrat();
  const [loading, setLoading] = useState(true); // Indicateur de chargement des données
  const [listBordereaux, setListBordereaux] = useState({}); // Liste de bordereaux
  const [regles, setRegles] = useState({}); // Regles associé au contrat rfa

  // Rafraichis les données
  const refetchData = () => {
    const refetch = async () => {
      await doGetBordereau();
    };
    refetch();
  };

  /**
   * Recupere les bordereaux en fonction du contrat
   */
  const doGetBordereau = React.useCallback(async () => {
    try {
      const data = await getBordereaux(contrat.con_code);
      setListBordereaux(data.bordereaux);
      setRegles(data.regles);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [contrat]);

  React.useEffect(() => {
    doGetBordereau();
  }, [doGetBordereau]);

  if (loading) return <LoaderApp />;
  if (!contrat) return null;
  if (!listBordereaux || !listBordereaux.length) {
    return (
      <MissingDataCard>
        Vous n'avez pas de litige à traiter
      </MissingDataCard>
    );
  }
  return (
    <div className="litige-wrap animate__animated animate__fadeIn">
      <div className="litige">
        <header className="litige__head">
          <h3>
            Réponse aux litiges
            <br />
            {contrat.con_nom}
          </h3>
          <section className="aide-section">
            <div className="intro-litige">
              <p>
                <span>Cher Partenaire,</span>
                <br />
                <br />
                Vous trouverez ci-dessous les lignes sur lesquelles
                nous avons constaté un écart entre votre déclaration
                et celle du magasin.
                <br />
                Nous vous remercions d'y répondre en cliquant sur le
                bouton&nbsp;
                <span className="text-icon">
                  <ModifyIcon width={20} height={20} />
                </span>
                &nbsp;ainsi qu'en laissant votre commentaire. 
                <br />
                N'oubliez pas de valider après avoir tout répondu.{' '}
                <br />
                Vous recevrez le récapitulatif de votre saisie par
                mail.
              </p>
            </div>
          </section>
        </header>
        <main className="litige__body">
          {listBordereaux &&
            !!listBordereaux.length &&
            listBordereaux.map((bordereau) => (
              <React.Fragment key={bordereau.lev_numero}>
                <LITbordereauTable
                  regles={regles}
                  bordereau={bordereau}
                  refetchData={refetchData}
                />
              </React.Fragment>
            ))}
        </main>
      </div>
    </div>
  );
};

export default Litige;
