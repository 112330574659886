import React, { useState } from 'react';
import './style.scss';
import logo from './logo-referencia.png';
import {
  deleteDeclarationMagasinRfa,
  saveDeclarationMagasinRfa,
} from '../../../../../../services/apis';
import Button from '../../../../button/Button';
import RFAinput from '../../../../inputs/_RFA/RFAinput';
import validation from './validation';

/**
 * Formulaire pour la saisie des données de déclaration d'un magasin
 * @param refreshData la fonction pour recharger les données
 * @param hide pour fermer le modal
 * @param notify pour afficher la notification
 */
const DECmagasinForm = ({
  magasin,
  refreshData,
  rfaContrat,
  hide,
}) => {
  const [errors, setErrors] = useState({}); // Erreurs pour le formulaire
  // Données pour le formulaire
  const [data, setData] = useState({});
  const nbColSuppMax = 9; // Nombre de colonnes supplémentaires pouvant être ajoutées
  const [loadingSave, setLoadingSave] = useState(false); // Indicateur de chargement lors d'un submit
  const [loadingDelete, setLoadingDelete] = useState(false); // Indicateur de chargement lors d'un submit

  // Modification des valeurs des champs
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setData({
      ...data,
      [name]: value,
    });
    console.log({
      ...data,
      [name]: value,
    });
  };

  // Suppression des données saisies
  const handleDelete = () => {
    try {
      if (!rfaContrat.statut_declaration) {
        setLoadingDelete(true);
        deleteDeclarationMagasinRfa(
          rfaContrat.con_code,
          rfaContrat.annee,
          magasin.mag_code
        ).then(() => {
          setLoadingDelete(false);
          hide();
          refreshData();
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = (event) => {
    // Validation check for inputs
    const validate = async () => {
      const errorBag = await validation(data);
      setErrors(errorBag);
      const hasErrors = Object.keys(errorBag).length;
      if (hasErrors) {
        throw new Error('Champs invalides');
      }
    };
    try {
      event.preventDefault();
      if (!rfaContrat.statut_declaration) {
        validate().then(() => {
          /**
           * Sauvegarde les données saisies pour ce magasin
           */
          const doSaveMagasinDeclaration = async (
            con_code,
            annee,
            mag_code,
            data
          ) => {
            await saveDeclarationMagasinRfa(
              con_code,
              annee,
              mag_code,
              data
            );
            refreshData();
          };
          setLoadingSave(true);
          // Enregistrement des données
          doSaveMagasinDeclaration(
            rfaContrat.con_code,
            rfaContrat.annee,
            magasin.mag_code,
            data
          ).then(() => {
            setLoadingSave(false);
            hide();
          });
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    setData({
      cm_ca_remonte: magasin.cm_ca_remonte ?? '',
      cm_ca_reel: magasin.cm_ca_reel ?? '',
      cm_ca_3: magasin.cm_ca_3 ?? '',
      cm_ca_4: magasin.cm_ca_4 ?? '',
      cm_ca_5: magasin.cm_ca_5 ?? '',
      cm_ca_6: magasin.cm_ca_6 ?? '',
      cm_ca_7: magasin.cm_ca_7 ?? '',
      cm_ca_8: magasin.cm_ca_8 ?? '',
      cm_ca_9: magasin.cm_ca_9 ?? '',
    });
  }, [magasin]);

  return (
    <form
      className="declaration-form"
      onSubmit={(event) => handleSubmit(event)}
    >
      {magasin.mag_depots && (
        <>
          <div className="depots-alert">
            <div>
              <span>dépot(s) concerné(s)</span>
              &nbsp;{magasin.mag_depots}
            </div>
          </div>
        </>
      )}
      <h4>Saisie déclaration</h4>
      <div className="declaration-form__bg">
        <img src={logo} alt="logo inedis" />
      </div>
      <div className="declaration-form__body">
        <div className="inputs-obligation">
          <RFAinput
            label={rfaContrat.regles.rce_libelle_2}
            name="cm_ca_remonte"
            title={rfaContrat.regles.rce_libelle_2}
            value={data.cm_ca_remonte}
            placeholder={`${
              !rfaContrat.statut_declaration
                ? 'Saisir un montant'
                : ''
            }`}
            disabled={rfaContrat.statut_declaration}
            onChange={(e) => handleChange(e)}
            error={errors.cm_ca_remonte}
            currency
          />
          <RFAinput
            label={rfaContrat.regles.rce_libelle_1}
            name="cm_ca_reel"
            title={rfaContrat.regles.rce_libelle_1}
            value={data.cm_ca_reel}
            placeholder={`${
              !rfaContrat.statut_declaration
                ? 'Saisir un montant'
                : ''
            }`}
            disabled={rfaContrat.statut_declaration}
            onChange={(e) => handleChange(e)}
            error={errors.cm_ca_reel}
            currency
          />
        </div>
        <div className="inputs-optionnal">
          {Array.from({ length: nbColSuppMax }, (_, i) => i + 3).map(
            (index) => {
              const libelle =
                rfaContrat.regles[`rce_libelle_${index}`];
              const value = data[`cm_ca_${index}`];
              const name = `cm_ca_${index}`;
              const error = errors[name];
              return (
                libelle && (
                  <RFAinput
                    key={name}
                    label={libelle}
                    name={name}
                    value={value}
                    placeholder={`${
                      !rfaContrat.statut_declaration
                        ? 'Saisir un montant'
                        : ''
                    }`}
                    currency
                    onChange={(e) => handleChange(e)}
                    error={error}
                    disabled={rfaContrat.statut_declaration}
                  />
                )
              );
            }
          )}
        </div>
      </div>

      <div className="declaration-form__foot">
        {!rfaContrat.statut_declaration ? (
          <>
            <Button
              type="submit"
              libelle="Enregistrer"
              color="primary-btn"
              disabled={rfaContrat.statut_declaration}
              loading={loadingSave}
            />
            <Button
              libelle="Supprimer"
              color="color-danger"
              onClick={() => handleDelete()}
              disabled={rfaContrat.statut_declaration}
              loading={loadingDelete}
            />
          </>
        ) : (
          <p> Consultation possible uniquement</p>
        )}
      </div>
    </form>
  );
};

export default DECmagasinForm;
