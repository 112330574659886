import React from 'react';
import './style.scss';
import {CONSTANTES, PATH} from "../../config/constants";
import {Link} from "react-router-dom";

const Home = () => {
  return (
    <div className="home-screen">
      <div className="home">
        <div>
          <h1 className="home-title">Portail Fournisseurs</h1>
           <div className="text-accueil">
            <p>
              <span>Cher Partenaire,</span> <br />
              <br />
              Vous trouverez ici les éléments nécessaires concernant
              votre déclaration de Chiffres d'Affaires pour
              l'année&nbsp;
              <span>{CONSTANTES.ANNEE_TRAITEMENT}</span>.
            </p>
            <p>
              Nous vous remercions de bien vouloir en prendre
              connaissance et de compléter les éléments avant le&nbsp;
              <span>{CONSTANTES.DATE_FIN_DECLARATION}</span> <br />
              Après avoir pris connaissance de ce&nbsp;
              <Link to="/aide" className="link">
                guide
              </Link>
              , rendez-vous dans l'onglet&nbsp;
              <Link to={PATH.DECLARATION_DETAIL} className="link">
                Déclaration
              </Link>
              .
              <br />
            </p>
            <div>
              Vous trouverez la liste des sociétés affiliées
              à&nbsp;Administration (= entités Facturables), et non chacun
              des magasins/dépôts (= points de livraison). <br />
              Nous attirons votre attention sur les éventuels cas
              particuliers de magasins nécessitant une déclaration
              scindée de votre chiffre d’affaires et pour lesquels
              nous avons pris soin de préciser en colonne les dates de
              validité (entrée/sortie) :
              <ul>
                <li>
                  - cas des magasins affiliés sur une partie de
                  l’année seulement
                </li>
                <li>
                  - cas d’un même magasin, même nom et même adresse,
                  présent sur deux lignes en raison par exemple d’un
                  changement de propriétaire.
                </li>
              </ul>
            </div>
            <p>
              Nous vous rappelons que tout manquement, retard ou
              approximation patente dans la réponse qui nous sera
              communiquée par vos services entrainera, outre ses
              conséquences sur nos relations commerciales,
              l’application d’indemnités selon les termes prévus dans
              nos accords.
              <br />
              <br />
              <span>Stéphane LECLERC,</span>
              <br />
              <span>Directeur</span>
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
