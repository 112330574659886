import React from 'react';
import BasicCheckbox from '../../../../../checkbox/BasicCheckbox';
import './style.scss';

const SelectedMagHeader = ({ checkOnly, handleCheckOnly }) => {
  return (
    <div className="selected-mag-head">
      <BasicCheckbox
        title="Afficher uniquement les magasins sélectionnés"
        checked={checkOnly}
        onChange={(event) => handleCheckOnly(event)}
      />
    </div>
  );
};

export default SelectedMagHeader;
