import React, { useCallback, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import LoaderApp from '../components/ui/loaders/LoaderApp';
import { PATH } from '../config/constants';
import ContratContext from './ContratContext';
import { getContrats } from '../services/contrat_apis';

const ContratProvider = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Indique que l'appel n'est pas fini, vrai par defaut
  const [contratsList, setContratsList] = useState([]); // Liste des contrats appartenant à l'utilisateur
  const [contrat, setContrat] = useState(null); // Contrat à manipuler

  /**
   * Assigne le contrat à manipuler.
   * Assigne automatiquement si l'utilisateur possède 1 seul contrat,
   * redirige vers une page de selection sinon.
   */
  const assignContrat = useCallback(async () => {
    try {
      const contrats = await getContrats(); // On récupère les contrats
      setContratsList(contrats);
      if (contrats.length) {
        // Redirection si plusieurs contrats pour le choix du contrat
        if (contrats.length > 1) {
          if (location.pathname !== PATH.SELECTION_CONTRAT) {
            navigate(PATH.SELECTION_CONTRAT, {
              state: { from: location },
            });
          }
          // Association a l'unique contrat
        } else {
          setContrat(contrats[0]);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [location, navigate]);

  /**
   * Si aucun contrat n'est selectionné, appel la fonction de selection de contrat.
   */
  React.useEffect(() => {
    if (!contrat) {
      assignContrat();
    }
  }, [assignContrat, contrat]);

  const value = {
    contrat,
    setContrat,
    contratsList,
  };

  if (loading) return <LoaderApp />;
  if (!contratsList.length) {
    return <p> Pas de contrats à manipuler.</p>;
  }
  return (
    <ContratContext.Provider value={value}>
      <Outlet />
    </ContratContext.Provider>
  );
};

export default ContratProvider;
