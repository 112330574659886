export const DOMAIN = {
  // dev lio
  //  SANCTUM_API: 'http://http://127.0.0.1:8000/',
  //  URL_API: 'http://127.0.0.1:8000/api/',
  //  URL: 'http://http://127.0.0.1/',

  // dev lucas
  // SANCTUM_API: "http://192.168.111.111/",
  // URL_API: "http://192.168.111.111/api/",
  // URL: "http://192.168.111.111/",

  // production
  SANCTUM_API: "https://api.inedis-referencia.com/",
  URL_API: "https://api.inedis-referencia.com/api/",
  URL: "https://api.inedis-referencia.com/",
};
