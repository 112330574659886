import axios from 'axios';
import { DOMAIN } from '../config/env';

/**
 *
 * @returns Les contrats liés à l'utilisateur
 */
export async function getContrats() {
  let contrats = {};
  await axios
    .get(DOMAIN.URL_API + 'contrat/user', {
      withCredentials: true,
    })
    .then((res) => {
      contrats = res.data;
    });
  return contrats;
}
