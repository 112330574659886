import React from 'react';

/**
 * Filtres pour la table
 */
const Filters = ({ allColumns }) => {
  if (!allColumns.length) return null;
  return (
    <div className="filter-columns">
      {allColumns.map(
        (column) =>
          column.hideable && (
            <div key={column.id}>
              <label className="filter-column">
                <div className="checkbox">
                  <input
                    type="checkbox"
                    {...column.getToggleHiddenProps()}
                  />
                </div>
                {column.Header}
              </label>
            </div>
          )
      )}
    </div>
  );
};

export default Filters;
