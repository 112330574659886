import React from 'react';
import useModal from '../../../../../hooks/useModal';
import useNotification from '../../../../../hooks/useNotification';
import { RappelDeclarationRFAAll } from '../../../../../services/contratsRFA_apis';
import BasicNotification from '../../../notifications/BasicNotification';
import Button from '../../../button/Button';
import ValidationModal from '../../ValidationModal';

const ADMrappelDeclarationAllModal = () => {
  const modalState = useModal();
  const { isShowingNot, toggleNotification } = useNotification();

  /**
   * Fonction envoyant un mail de rappel pour clôturer la déclaration à tous
   * les fournisseurs n'ayant pas encore clôturer leur déclaration
   */
  const handleRappelCloture = async () => {
    try {
      await RappelDeclarationRFAAll();
      toggleNotification();
      modalState.toggleModal();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Button
        libelle="Rappel aux fournisseurs"
        onClick={() => modalState.toggleModal()}
      />
      <ValidationModal
        modalState={modalState}
        onConfirmation={() => handleRappelCloture()}
        title="Rappel de déclarations aux fournisseurs"
      >
        <>
          Envoyer un mail de rappel aux fournisseurs n'ayant pas
          encore clôturé leur déclaration.
        </>
      </ValidationModal>
      <BasicNotification
        isShowing={isShowingNot}
        hide={toggleNotification}
        text="Modification"
      />
    </>
  );
};

export default ADMrappelDeclarationAllModal;
