import React, { useState } from 'react';
import './style.scss';
import * as Yup from 'yup';
import useAuth from '../../../hooks/useAuth';
import Button from '../../../components/ui/button/Button';
import { Link, Navigate } from 'react-router-dom';
import BasicInput from '../../../components/ui/inputs/BasicInput';
import PasswordInput from '../../../components/ui/inputs/PasswordInput';
import { PATH } from '../../../config/constants';

const Login = () => {
  const { onLogin, authed } = useAuth();
  const [error, setError] = useState(''); // Erreur sur submition des credentials
  const [errors, setErrors] = useState({}); // Erreur de validation des champs
  const [loading, setLoading] = useState(false); // Indicateur de chargement pour la connection
  const [data, setData] = useState({
    email: '',
    password: '',
  });

  // Validation des données du formulaire
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('* Ce champs est obligatoire'),
    password: Yup.string().required('* Ce champs est obligatoire'),
  });

  // Modification des valeurs des champs
  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    // Validation des champs de saisie
    const validate = async () => {
      const errorBag = {};
      await validationSchema
        .validate(data, { abortEarly: false })
        .catch((err) => {
          err?.inner.forEach((res) => {
            errorBag[res.path] = res.message;
          });
        });
      setErrors(errorBag);
      if (Object.keys(errorBag).length) {
        throw new Error('Champs invalides');
      }
    };
    try {
      validate().then(() => {
        setError('');
        setLoading(true);
        onLogin({ ...data }).catch((err) => {
          setLoading(false);
          setError(err.message);
        });
      });
    } catch (err) {
      console.log(err);
    }
  };

  if (authed) return <Navigate to={{ pathname: PATH.HOME }} />;
  return (
    <>
      <form className="form-login">
        <h3> Connexion </h3>
        <div className="form-login__inputs">
          <BasicInput
            type="email"
            name="email"
            label="Email"
            placeholder="Email"
            value={data.email}
            onChange={(event) =>
              handleChange(event.target.name, event.target.value)
            }
            error={errors.email}
            autoFocus
            autoComplete="email"
          />
          <PasswordInput
            type="password"
            name="password"
            label="Mot de passe"
            placeholder="Mot de passe"
            value={data.password}
            onChange={(event) =>
              handleChange(event.target.name, event.target.value)
            }
            error={errors.password}
            autoComplete="current-password"
          />
          <span className="error-form">{error && <>{error}</>}</span>
        </div>

        <div className="form-login__action">
          <Button
            loading={loading}
            libelle="Connexion"
            color="primary-btn"
            onClick={() => handleSubmit()}
          />
          <Link to="/forgot-password"> mot de passe oublié ?</Link>
        </div>
      </form>
    </>
  );
};

export default Login;
