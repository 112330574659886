import React from 'react';
import useModal from '../../../../../hooks/useModal';
import useNotification from '../../../../../hooks/useNotification';
import { RappelDeclarationRFA } from '../../../../../services/contratsRFA_apis';
import MailIcon from '../../../icons/MailIcon';
import BasicNotification from '../../../notifications/BasicNotification';
import Button from '../../../button/Button';
import ValidationModal from '../../ValidationModal';

const ADMrappelDeclarationModal = ({ contratRFA }) => {
  const modalState = useModal();
  const { isShowingNot, toggleNotification } = useNotification();

  /**
   * Fonction renvoyant un mail de rappel pour la cloture de la déclaration
   * @param contratRFA la déclaration concernée
   */
  const handleRappelCloture = async () => {
    try {
      await RappelDeclarationRFA(
        contratRFA.con_code,
        contratRFA.annee
      );
      toggleNotification();
      modalState.toggleModal();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Button
        libelle="Rappel"
        onClick={() => modalState.toggleModal()}
        color="color-tertiaire-variant"
        icon={<MailIcon with={15} height={15} />}
      />
      <ValidationModal
        modalState={modalState}
        onConfirmation={() => handleRappelCloture()}
        title="Mail rappel de déclaration"
      >
        <>
          Envoyer le rappel de déclaration pour {contratRFA.con_nom}
        </>
      </ValidationModal>
      <BasicNotification
        isShowing={isShowingNot}
        hide={toggleNotification}
        text="Mail envoyé"
      />
    </>
  );
};

export default ADMrappelDeclarationModal;
