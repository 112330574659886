import React, { useState } from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import HamburgerIcon from '../../../../ui/icons/HamburgerIcon';
import CancelIcon from '../../../../ui/icons/CancelIcon';
import useAuth from '../../../../../hooks/useAuth';

const Hamburger = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const { onLogout, isAdmin, isReferencia } = useAuth();
  const handleToggle = () => {
    setNavbarOpen((prev) => !prev);
  };
  const closeMenu = () => {
    setNavbarOpen(false);
  };

  return (
    <div className="hamburger__container">
      <button onClick={handleToggle}>
        {navbarOpen ? (
          <CancelIcon width={30} height={30} />
        ) : (
          <HamburgerIcon width={30} height={30} />
        )}
      </button>
      <div className={`menuHam ${navbarOpen ? ' showMenu' : ''}`}>
        <ul className="list">
          <li>
            <Link onClick={() => closeMenu()} to="/">
              <span className="link"> Accueil </span>
            </Link>
          </li>
          <li>
            <Link
              onClick={() => closeMenu()}
              to="/rfa/declaration-details"
            >
              <span className="link"> Declaration </span>
            </Link>
          </li>
          <li>
            <Link
              onClick={() => closeMenu()}
              to="/rfa/litige-details"
            >
              <span className="link"> Litiges </span>
            </Link>
          </li>
          {(isAdmin() || isReferencia()) && (
            <>
              <li>
                <Link
                  onClick={() => closeMenu()}
                  to="/Administration/declaration-dashboard"
                >
                  <span className="link">
                    Tableau de bord déclaration
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => closeMenu()}
                  to="/Administration/fournisseur-dashboard"
                >
                  <span className="link">
                    Tableau de bord fournisseurs
                  </span>
                </Link>
              </li>
            </>
          )}
          <li>
            <Link onClick={() => closeMenu()} to="/aide">
              <span className="link"> Aide </span>
            </Link>
          </li>
          <li>
            <Link onClick={() => closeMenu()} to="/profile">
              <span className="link"> Profile </span>
            </Link>
          </li>
          <li>
            <button type="button" onClick={onLogout}>
              <span className="link">Deconnexion</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Hamburger;
