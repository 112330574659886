import axios from 'axios';
import { DOMAIN } from '../config/env';

/**
 *
 * @returns Les bordereaux des litiges en fonction de l'année et du contrat
 */
export async function getBordereaux(con_code) {
  let bordereaux = {};
  await axios
    .get(`${DOMAIN.URL_API}litige/bordereau-rfa/${con_code}`, {
      withCredentials: true,
    })
    .then((res) => {
      bordereaux = res.data;
    });
  return bordereaux;
}

/**
 * Renseigne les informations pour un litige
 */
export async function updateLitige(litige) {
  await axios.put(
    DOMAIN.URL_API + 'litFichier/' + litige.lt_numero,
    { ...litige },
    { withCredentials: true }
  );
}

/**
 * Valide le bordereau et envoie un mail recapitulatif
 */
export async function validateLitige(lev_numero) {
  const data = {
    lev_numero: lev_numero,
  };
  await axios.post(
    DOMAIN.URL_API + 'litige/validation-rfa',
    { ...data },
    { withCredentials: true }
  );
}
