import React, { useState } from "react";
import "./style.scss";
import Button from "../../../../button/Button";
import RFAinput from "../../../../inputs/_RFA/RFAinput";
import logo from "./logo-referencia.png";
import validation from "./validations";
import { updateLitige } from "../../../../../../services/litiges_api";
import RFAtextArea from "../../../../inputs/_RFA/RFAtextArea";
import { formatPrix } from "../../../../../../utils/format";

const LITlitigeForm = ({ regles, litige, refetchData, bordereau, hide }) => {
  const [canUpdate, setCanUpdate] = useState(false); // Indicateur pour savoir si l'utilisateur peut modifier les litiges d'un bordereau
  const [openForm, setOpenForm] = useState(false);

  const [data, setData] = useState({
    lt_ca_non_rist_fourn: "",
    lt_ar_fourn: "",
    lt_fourn_comm: "",
    lt_col3: "",
    lt_col4: "",
    lt_col5: "",
    lt_col6: "",
    lt_col7: "",
    lt_col8: "",
    lt_col9: "",
  });
  const nbColSuppMax = 7; // Nombre de colonnes supplémentaires pouvant être ajoutées
  const [errors, setErrors] = useState({}); // Sac d'erreurs du formulaire
  const [loading, setLoading] = useState(false); // Indicateur de chargement lors d'un submit

  React.useEffect(() => {
    setData({
      lt_ca_non_rist_fourn: litige.lt_ca_non_rist_fourn ?? "",
      lt_ar_fourn: litige.lt_ar_fourn ?? "",
      lt_fourn_comm: litige.lt_fourn_comm ?? "",
      lt_col3: litige.lt_col3 ?? "",
      lt_col4: litige.lt_col4 ?? "",
      lt_col5: litige.lt_col5 ?? "",
      lt_col6: litige.lt_col6 ?? "",
      lt_col7: litige.lt_col7 ?? "",
      lt_col8: litige.lt_col8 ?? "",
      lt_col9: litige.lt_col9 ?? "",
    });
    if (
      litige.lt_ar_fourn ||
      litige.lt_ar_fourn ||
      litige.lt_ca_non_rist_fourn === 0
    )
      setOpenForm(true);
  }, [litige]);

  React.useEffect(() => {
    setCanUpdate(bordereau.lev_position === 1);
  }, [bordereau]);

  // Modification des valeurs des champs
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setData({
      ...data,
      [name]: value,
    });
  };

  /**
   * Validation de formulaire
   */
  const handleSubmit = (event) => {
    // Validation check for inputs
    const validate = async () => {
      const errorBag = await validation(data);
      setErrors(errorBag);
      const hasErrors = Object.keys(errorBag).length;
      if (hasErrors) {
        throw new Error("Champs invalides");
      }
    };
    try {
      event.preventDefault();
      validate().then(() => {
        const doUpdateLitige = async (data) => {
          await updateLitige({
            ...data,
            lt_numero: litige.lt_numero,
          });
          refetchData();
        };
        setLoading(true);
        // Enregistrement des données
        doUpdateLitige(data).then(() => {
          setLoading(false);
        });
      });
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Renseigne automatiquement la valeur de la declaration initiale
   */
  const handleConfirmInitialDeclaration = () => {
    setData({
      ...data,
      lt_ar_fourn: litige.lt_ar_depart ?? 0,
      lt_fourn_comm: "",
    });
    setOpenForm(true);
  };

  /**
   * Renseigne automatiquement la valeur de la declaration du magasin
   */
  const handleConfirmMagDeclaration = () => {
    setData({
      ...data,
      lt_ar_fourn: litige.lt_ar_mag ?? 0,
      lt_fourn_comm: "Je confirme la déclaration du magasin.",
    });
    setOpenForm(true);
  };

  const handleAutreMontant = () => {
    setData({
      ...data,
      lt_ar_fourn: "",
      lt_fourn_comm: "",
    });
    setOpenForm(true);
  };

  if (!litige || !regles) return null;
  return (
    <form className="litige-form" onSubmit={(data) => handleSubmit(data)}>
      <div className="litige-form__head">
        <div className="info-litige-overlay">
          <div>
            <div className="info-litige">
              <h4 className="title">Votre déclaration initiale</h4>
              <div className="info-litige__body">
                <div className="info">
                  <p>
                    <span> {regles.rce_libelle_2} </span>&nbsp;:&nbsp;
                    {formatPrix(litige.lt_ar_depart ?? 0)}
                  </p>
                </div>
              </div>
            </div>
            <div className="info-litige">
              <h4 className="title"> Déclaration du magasin</h4>
              <div className="info-litige__body">
                <div className="info">
                  <p>
                    <span> Volume d'achats </span>&nbsp;:&nbsp;
                    {formatPrix(litige.lt_ar_mag ?? 0)}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="action-litige">
            <div className="action">
              <Button
                color="color-secondary"
                libelle="Confirmer votre déclaration initiale"
                onClick={() => handleConfirmInitialDeclaration()}
                disabled={!canUpdate}
              />
            </div>
            <span className="or-separator">OU</span>
            <div className="action">
              <Button
                color="color-secondary"
                libelle="Confirmer la déclaration du magasin"
                onClick={() => handleConfirmMagDeclaration()}
                disabled={!canUpdate}
              />
            </div>
            <span className="or-separator">OU</span>
            <div className="action">
              <Button
                color="color-secondary"
                libelle="Saisir un autre montant"
                onClick={() => handleAutreMontant()}
                disabled={!canUpdate}
              />
            </div>
          </div>
        </div>
      </div>
      {openForm && (
        <>
          <div className="litige-form__body animate__animated animate__fadeIn">
            <h3> Votre réponse finale</h3>
            <div className="inline-inputs">
              <RFAinput
                label={`${regles.rce_libelle_2} final`}
                name="lt_ar_fourn"
                value={data.lt_ar_fourn}
                placeholder="Saisir un montant"
                onChange={(e) => handleChange(e)}
                error={errors.lt_ar_fourn}
                currency
                disabled={!canUpdate}
              />
              <RFAinput
                label="C.A Non Ristournable final"
                name="lt_ca_non_rist_fourn"
                value={data.lt_ca_non_rist_fourn}
                placeholder="Saisir le montant"
                onChange={(e) => handleChange(e)}
                error={errors.lt_ca_non_rist_fourn}
                currency
                disabled={!canUpdate}
              />
            </div>
            <RFAtextArea
              label="Commentaire"
              rows="2"
              maxLength="255"
              type="text"
              name="lt_fourn_comm"
              value={data.lt_fourn_comm}
              onChange={(e) => handleChange(e)}
              placeholder="Votre commentaire ..."
              error={errors.lt_fourn_comm}
              disabled={!canUpdate}
            />
            <div className="inline-inputs">
              {Array.from({ length: nbColSuppMax }, (_, i) => i + 3).map(
                (index) => {
                  const libelle = regles[`rce_libelle_${index}`];
                  const value = data[`lt_col${index}`];
                  const name = `lt_col${index}`;
                  const error = errors[name];
                  return (
                    libelle && (
                      <RFAinput
                        key={name}
                        label={libelle}
                        name={name}
                        value={value}
                        placeholder={`${canUpdate ? "Saisir un montant" : ""}`}
                        currency
                        onChange={(e) => handleChange(e)}
                        error={error}
                        disabled={!canUpdate}
                      />
                    )
                  );
                }
              )}
            </div>
          </div>
          <div className="litige-form__foot ">
            {canUpdate ? (
              <div className="inline-btn">
                <Button
                  type="submit"
                  libelle="Enregistrer"
                  color="color-tertiaire"
                  loading={loading}
                />
                <Button
                  libelle="Annuler"
                  color="color-neutral"
                  onClick={hide}
                />
              </div>
            ) : (
              <>
                <p> Consultation possible uniquement</p>
                <Button
                  libelle="Annuler"
                  color="color-neutral"
                  onClick={hide}
                />
              </>
            )}
          </div>
        </>
      )}
    </form>
  );
};

export default LITlitigeForm;
