import axios from 'axios';
import { DOMAIN } from '../config/env';

/**
 * generate a csrf-cookie to be able to call other apis function
 */
export async function setUpcsrfcookie() {
  try {
    axios
      .get(DOMAIN.URL_API + 'is-csrf-set', {
        withCredentials: true,
      })
      .then((res) => {
        if (!res.data) {
          throw new Error('csrf required');
        }
      });
    // Recupère le csrf token pour identifier l'utilisateur et autorisé les appels d'api
  } catch (err) {
    axios.get(DOMAIN.SANCTUM_API + 'sanctum/csrf-cookie', {
      withCredentials: true,
    });
  }
}

/**
 * Envoie un mail de réinitialisation de mot de passe
 */
export async function forgotPassword(email) {
  const data = {
    email: email,
  };

  return axios.post(
    DOMAIN.URL_API + 'forgot-password',
    { ...data },
    {
      withCredentials: true,
    }
  );
}

/**
 * Envoie un mail de réinitialisation de mot de passe
 */
export async function resetPassword(data) {
  return axios.post(
    DOMAIN.URL_API + 'reset-password',
    { ...data },
    {
      withCredentials: true,
    }
  );
}

export async function updatePassword(data) {
  return axios.put(
    DOMAIN.URL_API + 'user/password',
    { ...data },
    {
      withCredentials: true,
    }
  );
}

/**
 * return an user corresponding to the credentials
 */
export async function userLogin(loginCredentials) {
  let user = null;
  await axios
    .post(
      DOMAIN.URL_API + 'login',
      { ...loginCredentials },
      { withCredentials: true }
    )
    .then(async (res) => {
      user = await res.data;
    })
    .catch((err) => {
      throw new Error(err.response.data.errors);
    });

  return user;
}

/**
 * logout the user connected to the application
 */
export async function userLogout() {
  let isUserLogged = false;
  await axios
    .get(DOMAIN.URL_API + 'logout', {
      withCredentials: true,
    })
    .then((res) => {
      isUserLogged = res.data;
    });
  return isUserLogged;
}

/**
 * Return true if user is authenticated
 */
export async function isAuth() {
  let authed = false;
  await axios
    .get(DOMAIN.URL_API + 'authed', {
      withCredentials: true,
    })
    .then((res) => {
      authed = res.data[1];
    });
  return authed;
}

/**
 * Get user authenticated
 */
export async function userAuth() {
  let user = {};
  await axios
    .get(DOMAIN.URL_API + 'auth', {
      withCredentials: true,
    })
    .then((res) => {
      user = res.data;
    });
  return user;
}
