import React from 'react';

const ListIcon = ({ width = 20, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        strokeWidth="0"
        stroke="#523015"
        fill="#ebe5e1"
        r="2"
        cy="4"
        cx="4"
        transform="translate(2,2)"
      ></circle>
      <circle
        strokeWidth="1"
        stroke="#523015"
        fill="none"
        r="2"
        cy="4"
        cx="4"
      ></circle>
      <circle
        strokeWidth="0"
        stroke="#523015"
        fill="#ebe5e1"
        r="2"
        cy="12"
        cx="4"
        transform="translate(2,2)"
      ></circle>
      <circle
        strokeWidth="1"
        stroke="#523015"
        fill="none"
        r="2"
        cy="12"
        cx="4"
      ></circle>
      <circle
        strokeWidth="0"
        stroke="#523015"
        fill="#ebe5e1"
        r="2"
        cy="20"
        cx="4"
        transform="translate(2,2)"
      ></circle>
      <circle
        strokeWidth="1"
        stroke="#523015"
        fill="none"
        r="2"
        cy="20"
        cx="4"
      ></circle>
      <path
        strokeLinecap="round"
        strokeWidth="1"
        stroke="#523015"
        d="M10 12H22"
      ></path>
      <path
        strokeLinecap="round"
        strokeWidth="1"
        stroke="#523015"
        d="M10 4H22"
      ></path>
      <path
        strokeLinecap="round"
        strokeWidth="1"
        stroke="#523015"
        d="M10 20H22"
      ></path>
    </svg>
  );
};

export default ListIcon;
