import axios from 'axios';
import { DOMAIN } from '../config/env';

// Retourne le rfa con le plus récent associé au con_code passé en parametre
export async function getMostRecentRfaCon(con_code) {
  let rfacon = null;
  const data = {
    con_code: con_code,
  };
  await axios
    .post(
      DOMAIN.URL_API + 'most-recent-rfa',
      { ...data },
      { withCredentials: true }
    )
    .then(async (res) => {
      rfacon = await res.data;
    });
  //   rfacon = new RfaContrat(
  //     rfacon.annee,
  //     rfacon.con_code,
  //     rfacon.four_code,
  //     rfacon.statut_declaration,
  //     rfacon.lm_code,
  //     rfacon.regles,
  //     rfacon.ca
  //   );
  return rfacon;
}
/**
 * Cloture de la déclaration par l'utilisateur
 */
export async function clotureDeclarationRfa(con_code, annee) {
  await axios.get(
    DOMAIN.URL_API +
      'cloture-declaration-rfa/' +
      con_code +
      '/' +
      annee,
    {
      withCredentials: true,
    }
  );
}

/**
 * Toggle sur l'état de cloture d'une déclaration
 */
export async function toggleClotureDeclarationRFA(con_code, annee) {
  const data = {
    con_code: con_code,
    annee: annee,
  };

  await axios.put(
    DOMAIN.URL_API + 'toggle-declaration',
    { ...data },
    { withCredentials: true }
  );
}
/**
 * Envoie un mail de rappel pour la cloture des declarations
 */
export async function RappelDeclarationRFA(con_code, annee) {
  const data = {
    con_code: con_code,
    annee: annee,
  };
  await axios.post(
    DOMAIN.URL_API + 'rappel-declaration-rfa',
    { ...data },
    { withCredentials: true }
  );
}

/**
 * Envoie un mail de rappel pour la cloture des declarations a tous les fournisseurs
 * n'ayant pas encore clôturé
 */
export async function RappelDeclarationRFAAll() {
  await axios.get(DOMAIN.URL_API + 'rappel-declaration-rfa-all', {
    withCredentials: true,
  });
}

/**
 * Récupères tous les contrats rfa
 */
export async function getContratsRFA() {
  let contratsRFA = {};
  await axios
    .get(`${DOMAIN.URL_API}rfaContrat`, {
      withCredentials: true,
    })
    .then(async (res) => {
      contratsRFA = await res.data;
    });
  return contratsRFA;
}
