import React from 'react';
import FindIcon from '../../../icons/FindIcon';

/**
 * Barre de recherche pour la table
 */
const Search = ({ value, setValue, placeholder }) => {
  return (
    <div className="filtre">
      <div className="filtre__icon">
        <FindIcon />
      </div>
      <input
        type="text"
        className="filtre__input"
        placeholder={placeholder}
        value={value || ''}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
};

export default Search;
