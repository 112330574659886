import React from 'react';
import './style.scss';

import DECclotureModal from '../../../ui/modals/_RFA/_Declaration/DECclotureModal';
import DECresetMagsModal from '../../../ui/modals/_RFA/_Declaration/DECresetMagsModal';
import DECdownloadListModal from '../../../ui/modals/_RFA/_Declaration/DECdownloadListModal';
import DECimportListModal from '../../../ui/modals/_RFA/_Declaration/DECimportListModal';
import DECmagManquantModal from '../../../ui/modals/_RFA/_Declaration/DECmagasinManquantModal';
import DECtotalModal from '../../../ui/modals/_RFA/_Declaration/DECtotalModal';

const DECactionsList = ({
  refreshData,
  rfaContrat,
  refreshContratRFA,
}) => {
  return (
    <div className={`declaration-actions`}>
      <div className="btn-list">
        <DECtotalModal
          refreshContratRFA={refreshContratRFA}
          rfaContrat={rfaContrat}
        />
        {!rfaContrat.statut_declaration && (
          <>
            <DECmagManquantModal
              rfaContrat={rfaContrat}
              refreshData={refreshData}
            />
            <DECdownloadListModal rfaContrat={rfaContrat} />
            <DECimportListModal
              rfaContrat={rfaContrat}
              refreshData={refreshData}
            />
          </>
        )}
      </div>
      {!rfaContrat.statut_declaration && (
        <div className="btn-list">
          <DECresetMagsModal
            rfaContrat={rfaContrat}
            refreshData={refreshData}
          />
          <DECclotureModal rfaContrat={rfaContrat} />
        </div>
      )}
    </div>
  );
};

export default DECactionsList;
