import React, { useState } from "react";
import Button from "../../button/Button";
import MailBgIcon from "../../icons/MailBgIcon";
import CheckBgIcon from "../../icons/CheckBgIcon";
import TrashBgIcon from "../../icons/TrashBgIcon";
import Modal from "../Modal";
import "./style.scss";

const icons = {
  mail: <MailBgIcon />,
  check: <CheckBgIcon />,
  trash: <TrashBgIcon />,
};

const defaultIcon = "check";

const InputModal = ({
  children,
  onConfirmation,
  icon,
  initialEmail, // email initial à modifier
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState(initialEmail); // État pour le nouvel email

  // Execute l'événement on click
  const handleClick = () => {
    setLoading(true);
    setError("");
    onConfirmation(email) // Utilisez l'email modifié ici
      .then(() => props.modalState.toggleModal())
      .catch((error) => {
        console.error(error);
        setError("Une erreur est survenue");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Vérification de l'appartenance du libelle au tableau
  if (!icons[icon]) icon = defaultIcon;

  return (
    <Modal {...props}>
      <div className="validation-modal-wrap">
        <header className="validation-modal-bg">
          <div className="validation-mail-modal-icon">{icons[icon]}</div>
        </header>
        <div className="validation-modal">
          <h3 className="validation-modal__title">{props.title}</h3>
          <div className="validation-modal__content">
            <p>{children}</p>
            <br></br>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Entrez un nouvel email"
            />
          </div>

          <div className="validation-modal__action">
            <p>{error}</p>
            <div>
              <Button
                libelle="Confirmer"
                onClick={() => handleClick()}
                loading={loading}
              />
              <Button
                libelle="Annuler"
                color="color-neutral"
                onClick={props.modalState.toggleModal}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InputModal;
