import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LoaderApp from '../components/ui/loaders/LoaderApp';
import { PATH, ROLE } from '../config/constants';

import AuthContext from './AuthContext';
import {
  isAuth,
  setUpcsrfcookie,
  userAuth,
  userLogin,
  userLogout,
} from '../services/authentificationApis';

/**
 * Auth provider pour gerer un contexte d'user
 */
const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const standardRedirectPath = PATH.LITIGE_DETAIL; // Redirection standard apres le login

  const [authData, setAuthData] = useState({
    isLoading: true, // Indique si les données chargent ou non
    user: {}, // User authentifié
    authed: false, // Indicateur si un user est authentifié ou non
  });

  const { user, authed, isLoading } = authData;

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await setUpcsrfcookie(); // Enable api calls

        const isAuthenticated = await isAuth();
        let updatedAuthData = { ...authData, isLoading: false };

        // Check if user is authed
        if (isAuthenticated) {
          const activeUser = await userAuth();
          if (activeUser) {
            updatedAuthData = {
              ...updatedAuthData,
              user: activeUser,
              authed: isAuthenticated,
            };
          }
        } else {
          updatedAuthData = {
            ...updatedAuthData,
            authed: isAuthenticated,
          };
        }

        setAuthData(updatedAuthData);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  /**
   * Fonction de connection
   * @param event
   */
  const handleLogin = async (loginCredentials) => {
    let userLogged = {};
    if (!authed) {
      userLogged = await userLogin(loginCredentials);

      if (userLogged) {
        setAuthData({ ...authData, user: userLogged, authed: true });
      }
    }
    navigate(
      userLogged?.default_password
        ? PATH.UPDATE_PASSWORD
        : location.state?.from?.pathname || standardRedirectPath
    );
  };

  /**
   * Fonction de déconnection
   */
  const handleLogout = async () => {
    if (authed) {
      await userLogout();
      setAuthData({ ...authData, user: {}, authed: false });
    }

    navigate(PATH.LOGIN);
  };

  /**
   * @returns vrai si l'utilisateur à le role Administration
   */
  const isReferencia = () =>
    user.roles?.some((role) => role.id === ROLE.REFERENCIA);

  /**
   * @returns vrai si l'utilisateur à le role admin
   */
  const isAdmin = () =>
    user.roles?.some((role) => role.id === ROLE.ADMIN);

  // Données partagées dans le contexte
  const value = {
    user,
    authed,
    isLoading,
    isAdmin,
    isReferencia,
    onLogin: handleLogin,
    onLogout: handleLogout,
  };

  if (isLoading) return <LoaderApp />;
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
