import React from 'react';
import useModal from '../../../../../hooks/useModal';
import { sendNotificationLitige } from '../../../../../services/administration_apis';
import Button from '../../../button/Button';
import MailIcon from '../../../icons/MailIcon';
import ValidationModal from '../../ValidationModal';

const ADMnotifyLitigeModal = ({ bordereau }) => {
  const modalState = useModal();
  /**
   * Envoie un mail contentant le lien du portail.
   */
  const handleSendMail = async () => {
    try {
      await sendNotificationLitige(bordereau.contrat.con_code);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Button
        libelle="Notifier"
        color="color-tertiaire-variant"
        onClick={() => modalState.toggleModal()}
        icon={<MailIcon with={15} height={15} />}
      />
      <ValidationModal
        modalState={modalState}
        onConfirmation={() => handleSendMail()}
        title="Mail notification de litige"
      >
        <>
          Envoyer un mail pour signaler de nouveaux litiges <br />à{' '}
          {bordereau.contrat.con_nom}
        </>
      </ValidationModal>
    </>
  );
};

export default ADMnotifyLitigeModal;
