import React, { useState } from 'react';
import './style.scss';
import MissingDataCard from '../../../components/ui/cards/MissingDataCard';
import LoaderApp from '../../../components/ui/loaders/LoaderApp';
import ADMbordereauxTable from '../../../components/ui/tables/_Administration/ADMbordereauxTable';
import { getAllBordereaux } from '../../../services/administration_apis';

const DashboardLitige = () => {
  const [bordereauxList, setBordereauxList] = useState({});
  const [loading, setLoading] = useState(true);

  /**
   * Récupère les  bordereaux
   */
  const fetchData = React.useCallback(async () => {
    try {
      const data = await getAllBordereaux();
      setBordereauxList(data);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  /**
   * Rafraichis les données
   */
  const refetchData = () => {
    const refetch = async () => {
      await fetchData();
    };
    refetch();
  };

  React.useEffect(() => {
    const fetch = async () => {
      try {
        await fetchData();
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetch();
  }, [fetchData]);

  if (loading) return <LoaderApp />;
  if (!bordereauxList.length)
    return <MissingDataCard>Aucun bordereau</MissingDataCard>;
  return (
    <div className="dashboard-litige-overlay">
      <div className="dashboard-litige">
        <header className="dashboard-litige__header">
          <h3 className="title">Litiges</h3>
        </header>
        <div className="dashboard-litige__table">
          <ADMbordereauxTable
            bordereaux={bordereauxList}
            refetchData={refetchData}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardLitige;
