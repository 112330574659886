import React from 'react';
import logoIcon from './Inedis Réferencia - Logo quadri.png';
import './style.scss';

const LoaderApp = () => {
  return (
    <div className="loader-component">
      <div className="loarder-component__img">
        <img src={logoIcon} alt="logo inedis" />
      </div>
      <span className="loader-component__rolling"></span>
    </div>
  );
};

export default LoaderApp;
