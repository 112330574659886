import * as React from "react";
import "./style.scss";
import { DataGrid, frFR } from "@mui/x-data-grid";
import CustomPagination from "./Pagination";
import ADMportalLinkModal from "../../../modals/_Administration/ADMportalLinkModal";
import EditEmailModal from "./EditEmailModal";
import UserContrat from "./UserContrat";

const ADMfournisseursTable = ({ data, refreshData }) => {
  const [rows, setRows] = React.useState([]); // Lignes de la table
  const [pageSize] = React.useState(100); // Nombre de lignes affichées par défault
  const [rowModesModel, setRowModesModel] = React.useState({});

  const columns = [
    {
      field: "name",
      headerName: "Nom",
      width: 250,
      renderCell: (params) => {
        return (
          <span className="custom-cell" title={params.value}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="email-cell">
            <span className="custom-cell" title={params.value}>
              {params.value}
            </span>
            <EditEmailModal
              email={params.row.email}
              id={params.row.id}
              refreshData={refreshData}
            />
          </div>
        );
      },
    },
    {
      field: "activity",
      headerName: "Activité",
      width: 100,
      renderCell: (params) => {
        return (
          <span className="custom-cell" title={params.value}>
            {!!parseInt(params.value) ? "actif" : "inactif"}
          </span>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Créé le",
      width: 130,
    },
    {
      field: "updated_at",
      headerName: "Modifié le",
      width: 130,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 220,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <ADMportalLinkModal email={row.email} />,
          <UserContrat userId={row.id} />,
        ];
      },
    },
  ];

  React.useEffect(() => {
    setRows(data);
  }, [data]);

  return (
    <>
      <div className="fournisseurs-table">
        <DataGrid
          rows={rows}
          columns={columns}
          editMode="row"
          //getRowId={(row) => row.email}
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          components={{
            Pagination: CustomPagination,
          }}
          componentsProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          experimentalFeatures={{ newEditingApi: true }}
          pageSize={pageSize}
          autoPageSize
          pagination
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        />
      </div>
    </>
  );
};

export default ADMfournisseursTable;
