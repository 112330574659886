import React, { useState } from 'react';
import Modal from '../../Modal';
import { formatDate, formatPrix } from '../../../../../utils/format';
import CurrencyEuroIcon from '../../../icons/CurrencyEuroIcon';
import InfoIcon from '../../../icons/InfoIcon';
import './style.scss';

/**
 * Donne les informations concernant une déclaration tel que les totaux des CA, la date de clôture etc
 * @param declaration
 * @returns
 */
const ADMinfoDeclarationModal = ({ declaration, modalState }) => {
  const [libelles, setLibelles] = useState({
    libelle_reel: '', // Libelle du CA reel
    libelle_remonte: '', // Libelle du CA remonte
  });

  React.useEffect(() => {
    setLibelles({
      libelle_reel: declaration.rce_libelle_1,
      libelle_remonte: declaration.rce_libelle_2,
    });
  }, [declaration]);

  return (
    <Modal
      modalState={modalState}
      title={declaration.con_nom}
      color="primary-modal-bis"
    >
      <div className="info-declaration-modal">
        <div className="info-declaration-modal__body">
          <div className="section">
            <h4 className="title">
              <span>
                <CurrencyEuroIcon width={20} height={20} />
              </span>
              CA
            </h4>
            <ul className="forward">
              <li>
                {libelles.libelle_remonte} :&nbsp;
                {formatPrix(
                  (declaration.remonteCA ?? 0) +
                    (declaration.remonteCA_autre ?? 0)
                )}
              </li>
              <li>
                {libelles.libelle_reel} :&nbsp;
                {formatPrix(
                  (declaration.reelCA ?? 0) +
                    (declaration.reelCA_autre ?? 0)
                )}
              </li>
            </ul>
            <ul>
              <li>
                {libelles.libelle_remonte} :&nbsp;
                {formatPrix(declaration.remonteCA)}
              </li>
              <li>
                {libelles.libelle_reel} :&nbsp;
                {formatPrix(declaration.reelCA)}
              </li>
              <li>
                {libelles.libelle_remonte} magasins autres :&nbsp;
                {formatPrix(declaration.remonteCA_autre)}
              </li>
              <li>
                {libelles.libelle_reel} magasins autres :&nbsp;
                {formatPrix(declaration.reelCA_autre)}
              </li>
            </ul>
          </div>
          <div className="section">
            <h4 className="title">
              <span>
                <InfoIcon width={20} height={20} />
              </span>
              Statuts
            </h4>
            <ul>
              <li>
                statut clôture :&nbsp;
                {!!declaration.statut_declaration ? (
                  <>
                    clôturé le&nbsp;
                    {formatDate(declaration.date_declaration)}
                  </>
                ) : (
                  <> En cours </>
                )}
              </li>
              <li>
                statut récupération de données :&nbsp;
                {!!declaration.statut_declaration ? (
                  <>
                    récupéré le&nbsp;
                    {formatDate(declaration.date_recuperation)}
                  </>
                ) : (
                  <> Non récupéré </>
                )}
              </li>
            </ul>
          </div>
        </div>
        <div className="info-declaration-modal__foot"></div>
      </div>
    </Modal>
  );
};

export default ADMinfoDeclarationModal;
