import React from 'react';

const TrashBgIcon = ({ height = 100, width = 100 }) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="0"
        stroke="#523015"
        fill="#ebe5e1"
        d="M16.13 22H7.87C7.37 22 6.95 21.63 6.88 21.14L5 8H19L17.12 21.14C17.05 21.63 16.63 22 16.13 22Z"
        transform="translate(2,2)"
      ></path>
      <path
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="0.5"
        stroke="#523015"
        fill="none"
        d="M16.13 22H7.87C7.37 22 6.95 21.63 6.88 21.14L5 8H19L17.12 21.14C17.05 21.63 16.63 22 16.13 22Z"
      ></path>
      <path
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="0.5"
        stroke="#523015"
        d="M3.5 8H20.5"
      ></path>
      <path
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="0.5"
        stroke="#523015"
        d="M10 12V18"
      ></path>
      <path
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="0.5"
        stroke="#523015"
        d="M14 12V18"
      ></path>
      <path
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="0"
        stroke="#523015"
        fill="#ebe5e1"
        d="M16 5H8L9.7 2.45C9.89 2.17 10.2 2 10.54 2H13.47C13.8 2 14.12 2.17 14.3 2.45L16 5Z"
        transform="translate(2,2)"
      ></path>
      <path
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="0.5"
        stroke="#523015"
        fill="none"
        d="M16 5H8L9.7 2.45C9.89 2.17 10.2 2 10.54 2H13.47C13.8 2 14.12 2.17 14.3 2.45L16 5Z"
      ></path>
      <path
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="0.5"
        stroke="#523015"
        d="M3 5H21"
      ></path>
    </svg>
  );
};

export default TrashBgIcon;
