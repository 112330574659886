import axios from 'axios';
import { PATH } from '../config/constants';
import { DOMAIN } from '../config/env';

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // const originalRequest = error.config;
    // return axios(originalRequest);

    // Redirige vers la page de login si le CSRF token n'est plus valide
    if (error.response && error.response.status === 500) {
      // Affiche un message d'erreur générique
      alert("Une erreur s'est produite. Veuillez recharger la page.");
    }
    if (error.response.status === 419) {
      // Regenere le token
      axios.get(DOMAIN.SANCTUM_API + 'sanctum/csrf-cookie', {
        withCredentials: true,
      });
      window.location.href = PATH.LOGIN;
    }
    return Promise.reject(error);
  }
);
