import React from 'react';
import './style.scss';
import useModal from '../../../../../../hooks/useModal';
import { validateLitige } from '../../../../../../services/litiges_api';
import Button from '../../../../button/Button';
import ValidationModal from '../../../ValidationModal';

const LITvalidationModal = ({
  canValidate,
  lev_numero,
  refetchData,
}) => {
  const modalState = useModal();

  const handleLitigeValidation = async () => {
    await validateLitige(lev_numero);
    refetchData();
  };

  return (
    <>
      <Button
        libelle="Valider les litiges"
        onClick={() => modalState.toggleModal()}
        color="primary-btn"
        disabled={!canValidate}
        title="Veuillez répondre à tous les litiges avant de valider."
      />
      <ValidationModal
        modalState={modalState}
        onConfirmation={() => handleLitigeValidation()}
        title="Valider vos litiges"
        icon="mail"
      >
        <div className="validation-litige-text">
          <span>
            Une fois confirmé, vous ne pourrez plus revenir sur vos
            litiges.
          </span>
          <br />
          Vous recevrez une copie de ce que vous avez renseigné par
          mail.
        </div>
      </ValidationModal>
    </>
  );
};

export default LITvalidationModal;
