import { Routes, Route, Navigate } from 'react-router-dom';
import AppLayout from './components/layouts/AppLayout';
import Home from './screens/home';
import Login from './screens/authentification/Login';
import Unauthorized from './screens/Unauthorized';
import ReferenciaRoute from './components/guards/ReferenciaRoute';
import Authentification from './screens/authentification';
import ForgotPassword from './screens/authentification/ForgotPassword';
import ResetPassword from './screens/authentification/ResetPassword';
import Profile from './screens/profile';
import SelectionContrat from './screens/selectionContrat';
import DashboardDeclaration from './screens/administration/DashboardDeclaration';
import AuthRoute from './components/guards/AuthRoute/index.js';
import DashboardFournisseur from './screens/administration/DashboardFournisseur';
import DashboardLitige from './screens/administration/DashboardLitige';
import Aide from './screens/aide';
import { PATH } from './config/constants';
import Litige from './screens/rfa/litige';
import ContratProvider from './contexts/ContratProvider';
import Declaration from './screens/rfa/declaration';
import Administration from './screens/administration';

const App = () => {
  return (
    <>
      <Routes>
        <Route path="*" element={<Navigate to={PATH.NOT_FOUND} />} />
        <Route element={<Authentification />}>
          <Route path={PATH.LOGIN} element={<Login />} />
          <Route
            path={PATH.RESET_PASSWORD}
            element={<ResetPassword />}
          />
          <Route
            path={PATH.FORGOT_PASSWORD}
            element={<ForgotPassword />}
          />
        </Route>
        <Route element={<AuthRoute outlet={<AppLayout />} />}>
          <Route
            path={PATH.UNAUTHORIZED}
            element={<Unauthorized />}
          />
          <Route path={PATH.HOME} element={<Home />} />
          <Route path={PATH.AIDE} element={<Aide />} />
          <Route element={<ContratProvider />}>
            <Route
              path={PATH.DECLARATION_DETAIL}
              element={<Declaration />}
            />
            <Route path={PATH.LITIGE_DETAIL} element={<Litige />} />
            <Route
              path={PATH.SELECTION_CONTRAT}
              element={<SelectionContrat />}
            />
          </Route>

          <Route
            path={PATH.DASHBOARD}
            element={<ReferenciaRoute outlet={<Administration />} />}
          >
            <Route
              path={PATH.DASHBOARD_DECLARATIONS}
              element={<DashboardDeclaration />}
            />
            <Route
              path={PATH.DASHBOARD_USERS}
              element={<DashboardFournisseur />}
            />
            <Route
              path={PATH.DASHBOARD_LITIGES}
              element={<DashboardLitige />}
            />
          </Route>
          <Route path={PATH.UPDATE_PASSWORD} element={<Profile />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
